import React from 'react'

import * as S from './styled'
import BrandCard from './BrandCard'
import PinkLine from '../shared/PinkLine'

const ExamLocations = (props) => {
  const brandsData = props.examsLocation
  const data = props.data

  return (
    <>
      <S.ExamLocationsWrapper>
        <S.ExamLocationsTitle>
          {data.title}
        </S.ExamLocationsTitle>

        <S.ExamLocationsDescription>
          {data.demonstrative_text}
        </S.ExamLocationsDescription>

        <S.CardsContainer>
          {brandsData.map(brand => (
            <BrandCard 
              key={brand.id}
              acf={brand.acf}
              brand={brand.title}
            />
          ))}
        </S.CardsContainer>
      </S.ExamLocationsWrapper>
      <PinkLine />
    </>
  )
}

export default ExamLocations
