import styled from 'styled-components'
import media from 'styled-media-query'
import { alvTokens } from '@dasa-health/alma-tokens'
import { Container } from 'components/shared/container'

export const ExamLocationsWrapper = styled(Container)`
  max-width: 1092px;

  ${media.lessThan('medium')`
    padding: 0 1.6rem;
    width: calc(100vw - 3.2rem);
  `}
`

export const FilterRowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 5rem;
`

export const FilterContainer = styled.div`
  margin-bottom: 2rem;
  width: 30rem;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`

export const FilterTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--pink);
  text-transform: uppercase;
  margin-bottom: 2rem;
`

export const CardsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  list-style-type: none;

  ${media.lessThan('small')`
    padding-left: 0;
  `}

  ${media.between('medium', 'huge')`
    padding-left: 0;
  `}
`

export const ErrorMessage = styled.p`
  color: var(--pink);
  text-align: center;
  font-size: 40px;
`

export const SearchMessage = styled.p`
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
  color: var(--white);
  background: ${props => props.theme.colors.brand.primary[10]};
  text-transform: uppercase;
  width: 30%;
  text-align: center;
  margin: 0 auto;

  ${media.lessThan('medium')`
    width:70%;
  `}

  padding: 1rem;
  border-radius: 4px;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
`

export const ExamLocationsTitle = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  color: ${props => props.theme.colors.brand.primary[10]};
  margin: 2.4rem 0;

  ${media.lessThan('medium')`
    font-size: 20px;
    margin: 0 0 2.4rem;
  `}
`

export const ExamLocationsDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: ${alvTokens.ColorPaletteGray30};
  max-width: 75.9rem;
  margin: 0 0 4rem;
  
  ${media.lessThan('medium')`
    max-width: 100%;
    margin: 0 0 6.4rem;
  `}
`
